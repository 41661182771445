var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { defineComponent } from 'vue';
import testsStore from '@/store/tests.store';
import { IonContent, IonCard, IonCardContent, IonCardHeader, IonPage, IonInput, IonItem, IonLabel, IonButton, } from '@ionic/vue';
import Swal from 'sweetalert2';
import { Preferences } from '@capacitor/preferences';
import authStore from '@/store/auth.store';
export default defineComponent({
    name: 'validate2FA',
    components: {
        IonContent: IonContent,
        IonCard: IonCard,
        IonCardContent: IonCardContent,
        IonCardHeader: IonCardHeader,
        IonPage: IonPage,
        IonInput: IonInput,
        IonItem: IonItem,
        IonLabel: IonLabel,
        IonButton: IonButton,
    },
    data: function () {
        return {
            code2fa: '',
            mobileNum: ''
        };
    },
    computed: {
        formValid: function () {
            return this.code2fa != null && this.code2fa.length > 3;
        },
        getCurrentYear: function () {
            return new Date().getFullYear();
        }
    },
    methods: {
        validate: function () {
            return __awaiter(this, void 0, void 0, function () {
                var validation;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, authStore.validate2faCode({ code2fa: this.code2fa })];
                        case 1:
                            validation = _a.sent();
                            if (!validation) return [3 /*break*/, 4];
                            Preferences.remove({ key: 'needs2FA' });
                            return [4 /*yield*/, authStore.getUserDetails()];
                        case 2:
                            _a.sent();
                            return [4 /*yield*/, testsStore.getTests(0)];
                        case 3:
                            _a.sent();
                            this.$router.push({ name: 'tests.index' });
                            return [3 /*break*/, 5];
                        case 4:
                            Swal.fire("", this.$t(authStore.state.send2fa.errorMessage), "error");
                            _a.label = 5;
                        case 5: return [2 /*return*/];
                    }
                });
            });
        },
        resendCode: function () {
            return __awaiter(this, void 0, void 0, function () {
                var validation;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, authStore.resend2facode()];
                        case 1:
                            validation = _a.sent();
                            Swal.fire("", this.$t('tester_mobile_validation_resend'), "success");
                            return [2 /*return*/];
                    }
                });
            });
        }
    },
    beforeMount: function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, authStore.getMobileFor2FA()];
                    case 1:
                        _a.sent();
                        this.mobileNum = authStore.state.userData.mobileNum;
                        this.mobileNum = '******' + this.mobileNum.slice(-3);
                        return [2 /*return*/];
                }
            });
        });
    }
});
